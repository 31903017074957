<template>
  <div :id="id">
    <ckeditor :editor="editor" @ready="onReady" v-on:focus="handleFocus" v-on:blur="handleBlur" v-model="editorContent" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';

import ApiService from "../core/services/api.service";
import MediaPlugin from "../core/plugins/media/MediaPlugin";
import FullScreen from "../core/plugins/fullscreen/FullScreen";
import UploadImageAdapterPlugin from "../core/plugins/image/UploadImageAdapterPlugin";

export default {
  name: "Editor",
  props: {
    content: String,
    id: String,
    full: Boolean
  },
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      editor: ClassicEditor,
      editorContent: "",
      editorConfig: {
        extraPlugins: [MediaPlugin, UploadImageAdapterPlugin, FullScreen],
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          UnderlinePlugin,
          StrikethroughPlugin,
          LinkPlugin,
          ParagraphPlugin,
          HeadingPlugin,
          ImageUpload,
          Image,
          ImageToolbar,
          ImageStyle,
          ImageResize,
          ImageResizeEditing,
          ImageResizeHandles,
          LinkImage,
          MediaEmbed,
          TablePlugin,
          Table,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          Alignment,
          ListPlugin,
          FileRepository,
          Mention,
          SpecialCharacters,
          ImageCaption
        ],
        image: {
          toolbar: [ 'toggleImageCaption', 'imageTextAlternative', 'imageStyle:block', 'imageStyle:side', 'imageStyle:alignLeft', 'linkImage', 'resizeImage' ],
        },
        table: {
          contentToolbar: [
            'tableColumn', 'tableRow', 'mergeTableCells',
            'tableProperties', 'tableCellProperties'
          ]
        },
        toolbar: {
          items: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'bulletedList', 'numberedList', 'alignment',
            '|',
            'link',
            'imageUpload',
            'mediaPlugin',
            'mediaEmbed',
            'insertTable',
            'specialCharacters',
            'fullScreen',
          ],
        },
        mention: {
          feeds: [
            {
              marker: '@',
              feed: this.getUsers
            }
          ]
        },
        mediaEmbed: {
          extraProviders: [
            {
              name: 'bcdcnt',
              url: /(.*)(bai-hat|dan-ca|khi-nhac|thanh-vien-hat|tieng-tho)\/(.*).html(.*)$/
            },
            {
              name: 'mp3',
              url: /(.*).(mp3|mp4)$/
            },
            {
              name: 'googleDocs',
              url: /(.*)docs\.google\.com(.*)/
            }
          ]
        }
      }
    }
  },
  methods: {
    handleFocus() {
      window.postMessage({action: "isEditing", value: true}, window.location.origin);
    },
    handleBlur() {
      window.postMessage({action: "isEditing", value: false}, window.location.origin);
    },
    async getUsers(q) {
      let query = `query($q: Mixed!) {
        users(orderBy: [{column: "views", order: DESC}], first: 5, where: {AND: [{column: "username", value: $q, operator: LIKE}, {column: "is_banned", value: "N"}]}) {
          data {
            username
            id
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              data.data.users.data.map((item) => {
                item.userId = item.id;
                item.id = "@" + item.username;
                item.name = item.username;
              });
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    onReady( editor )  {
      editor.plugins.get( 'SpecialCharacters' ).addItems( 'Cảm xúc', [
        { title: 'Face with Tears of Joy', character: '😂' },
        { title: 'Grinning Face', character: '😀' },
        { title: 'Smiling Face with Smiling Eyes', character: '😊' },
        { title: 'Winking Face', character: '😉' },
        { title: 'Smiling Face with Heart-Shaped Eyes', character: '😍' },
        { title: 'Face Throwing a Kiss', character: '😘' },
        { title: 'Kissing Face with Closed Eyes', character: '😚' },
        { title: 'Kissing Face', character: '😗' },
        { title: 'Kissing Face with Smiling Eyes', character: '😗' },
        { title: 'Face with Stuck-Out Tongue and Winking Eye', character: '😙' },
        { title: 'Face with Stuck-Out Tongue and Tightly-Closed Eyes', character: '😝' },
        { title: 'Face with Stuck-Out Tongue', character: '😛' },
        { title: 'Flushed Face', character: '😳' },
        { title: 'Grinning Face with Smiling Eyes', character: '😁' },
        { title: 'Pensive Face', character: '😔' },
        { title: 'Relieved Face', character: '😌' },
        { title: 'Unamused Face', character: '😒' },
      ] );
      editor.plugins.get( 'SpecialCharacters' ).addItems( 'Âm nhạc', [
        { title: 'Nốt đen', character: '♩' },
        { title: 'Nốt móc đơn', character: '♪' },
        { title: 'Móc đơn liền', character: '♫' },
        { title: 'Móc kép liền', character: '♬' },
        { title: 'Dấu giáng', character: '♭' },
        { title: 'Dấu bình', character: '♮' },
        { title: 'Dấu thăng', character: '♯' },
      ] );
    }
  },
  watch: {
    editorContent: {
      handler(newVal) {
        this.$emit('update:content', newVal)
      }
    },
    content: {
      handler(newVal) {
        this.editorContent = newVal;
      }
    }
  },
  created() {
    this.editorContent = this.content;

    if (this.full) {
      let items = this.editorConfig.toolbar.items;
      items.unshift("heading");
      this.editorConfig.toolbar.items = items;
    }
  },
  mounted() {

  }
}
</script>
